

import { defineComponent } from 'vue';
import FooterComponent from '@/components/FooterComponent.vue'; // @ is an alias to /src
import HeaderComponent from './components/HeaderComponent.vue';
export default defineComponent({
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent,
  },
});
