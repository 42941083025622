

import { useUserStore } from "@/store";
import { mapState } from "pinia";
import { defineComponent } from 'vue';
import UserIcon from "./UserIconComponent.vue";

export default defineComponent({
    name: 'HeaderComponent',
    components: {
        UserIcon
    },
    data() {
        return {
            burgerOut: false
        }

    },
    computed: {
        ...mapState(useUserStore, ["isLoggedIn", "userName"])
    },
    methods: {
        handleBurger: function() {
            this.burgerOut = !this.burgerOut;
        },
    }

});

