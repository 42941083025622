import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13b77fd1"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "fa-stack fa-lg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass({icon:_ctx.is_icon, 'is-large': _ctx.is_icon}),
    style: _normalizeStyle({height: _ctx.height})
  }, [
    (_ctx.have_circle)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _createElementVNode("i", {
            style: _normalizeStyle(_ctx.styles),
            class: "fas fa-circle fa-stack-2x"
          }, null, 4),
          _createElementVNode("i", {
            class: _normalizeClass(_ctx.classes)
          }, null, 2)
        ]))
      : (_openBlock(), _createElementBlock("i", {
          key: 1,
          class: _normalizeClass(_ctx.classes)
        }, null, 2))
  ], 6))
}