<template>
    <div class="container">
        <img alt="Vue logo" src="../assets/logo.png" />
        <p>Overwhelmed by figuring out what to eat, what to work on next, or what needs to be cleaned?</p>
        <p>Let Honeydew take care of that for you</p>
        <p>Managed chores, meal planning, house projects in one easy place.</p>
        <p class="my-3">
            <a href="/signup" class="button is-outlined is-link is-large is-rounded">Signup for an account</a>
        </p>
        <p>
            Or if you already have an account, use a device that's already logged in to generate a magic key.
        </p>
    </div>
</template>