

import { useUserStore } from "@/store";
import { mapActions, mapState } from "pinia";
import { defineComponent } from 'vue';
import Turnstile from 'cfturnstile-vue3';

export default defineComponent({
  name: 'SignupView',
  components: {
    "cfturnstile": Turnstile
  },
  data() {
    return {
      name: "",
      error: "",
      invite_data: new URLSearchParams(window.location.search).get('k') || '',
      recovery_code: "",
      turnstile_sitekey: "0x4AAAAAAABtOYbnvjMckFhC",
      turnstile_response: '',
    }

  },
  computed: {
    ...mapState(useUserStore, ["isLoggedIn", "userName", "thinking"])
  },
  methods: {
    ...mapActions(useUserStore, ["signUp"]),
    turnstile_verify: function (token:string) {
      this.turnstile_response = token;
    },
    copyRecoveryCode: async function () {
      try {
        await navigator.clipboard.writeText(this.recovery_code);
      }
      catch {
        this.error = "Could not copy to clipboard";
      }
    },
    press_signup: async function () {
      if (this.$refs["signup-form"] == undefined) {
        this.error="Unable to find form";
        return;
      }
      this.error = "";
      const result = await this.signUp(this.name, this.invite_data, this.turnstile_response);
      if (result.success) {
        this.recovery_code = `${result.data.user_id}:${result.data.recovery_key}`;
      }
      else {
        this.error = result.message;
      }
    },
  }

});

