

import { useUserStore } from "@/store";
import { mapState } from "pinia";
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SignoutView',
  computed: {
    ...mapState(useUserStore, ["isLoggedIn"])
  },
  data() {
    return {
      error:"",
    }

  },
  methods: {
    signout: async function () {
      const store = useUserStore()
      const result = await store.signOut();
      if (result.success == false) {
        this.error = result.message;
      }
    },
  }
});
