
import { defineComponent } from 'vue';
import { mapState } from "pinia";
import { useUserStore } from "@/store";
import type {TaskId} from "../../functions/db_types";
import ChoreIconComponent from "@/components/ChoreIconComponent.vue";

export default defineComponent({
  name: 'HomeView',
  components: {
    ChoreIconComponent
  },
  computed: {
    household_name: function () {
      const household = useUserStore().household;
      if (household == null) return "";
      return household.name;
    },
    ...mapState(useUserStore, ["currentDate", "currentChore", "household_chores", "currentTask", "currentProject"])
  },
  data() {
    return {
      error: ""
    }

  },
  methods: {
    complete_chore: async function (id: string | null) {
      if (id == null) {
        this.error = "Chore ID is null";
        return;
      }
      const status = await useUserStore().ChoreComplete(id);
      if (status.success == false) {
        this.error = status.message
      }
    },
    another_chore: async function () {
      const status = await useUserStore().ChoreGetAnother();
      if (status.success == false) {
        this.error = status.message
      }
    },
    complete_task:  async function (id: TaskId|null) {
      if (id == null) return;
      const status = await useUserStore().TaskComplete(id);
      if (status.success == false) {
        this.error = (status as any).message
      }
    },
  }
});
