import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeView = _resolveComponent("HomeView")!
  const _component_LandingView = _resolveComponent("LandingView")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isLoggedIn)
      ? (_openBlock(), _createBlock(_component_HomeView, { key: 0 }))
      : (_openBlock(), _createBlock(_component_LandingView, { key: 1 }))
  ]))
}