import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70c8b124"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fa-stack fa-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: "icon is-large",
    style: _normalizeStyle({height: _ctx.height})
  }, [
    _createElementVNode("span", _hoisted_1, [
      _createElementVNode("i", {
        style: _normalizeStyle(_ctx.styles),
        class: "fas fa-circle fa-stack-2x"
      }, null, 4),
      _createElementVNode("i", {
        class: _normalizeClass(_ctx.classes)
      }, null, 2)
    ])
  ], 4))
}